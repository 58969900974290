import '../auto/global';
import '../auto/stores';
// Importando o alias para a pasta de componentes.
import '#';
import Carousel from '../auto/carousel';
import Choices from '../auto/choices';
import MicroModal from '../auto/micromodal';
import serviceFactory from '../auto/autoForceServiceFactory';
import serviceConversionFactory from '../auto/autoBoxOfficeServiceFactory';
import railsDateToChronometer from '../auto/utils/railsDateToChronometer';
import { pushDataToDataLayer } from '../auto/utils/pushDataToDataLayer';

// Criando um service que será armazenado como Singleton.
window.service = serviceFactory();
window.serviceConversion = serviceConversionFactory();

window.Carousel = Carousel;
window.Choices = Choices;
window.MicroModal = MicroModal;

window.railsDateToChronometer = railsDateToChronometer;
window.pushDataToDataLayer = pushDataToDataLayer;

if (process.env.NODE_ENV === 'development') {
  // Necessário para fazer o Preact ficar disponível no React Dev Tools
  require('preact/debug');

  // Adicionando o app do guide apenas em desenvolvimento
  $(document).ready(function () {
    // require('./guide-app.js');
  });
}

$(document).ready(function () {
  // create Swiper object
  const slide = new Carousel('.slide', {
    autoplay: {
      delay: 5000
    },
    speed: 500,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    on: {
      init: function () {
        window.videoBG.adjustsHeight('.slide')
      }
    }
  });

  const slideThumbs = new Carousel('.swiper-thumbs-container', {
    direction: 'vertical',
    slidesPerView: 5,
    watchSlidesVisibility: true,
    watchSlidesProgress: true
  });

  const slideImages = new Carousel('.swiper-images-container', {
    direction: 'horizontal',
    navigation: {
      nextEl: '.slide-images__control--next',
      prevEl: '.slide-images__control--prev'
    },
    thumbs: {
      swiper: slideThumbs
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    breakpoints: {
      767: {
        direction: 'vertical'
      }
    }
  });
});
